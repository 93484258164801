<template>
	<el-aside class="layout-aside" :class="setCollapseWidth" v-if="clientWidth > 1000">
		<el-scrollbar class="flex-auto" ref="layoutAsideRef">
			<Vertical :menuList="menuList" :class="setCollapseWidth" />
		</el-scrollbar>
	</el-aside>
	<el-drawer :visible.sync="getThemeConfig.isCollapse" :with-header="false" direction="ltr" size="220px" v-else>
		<el-aside class="layout-aside w100 h100">
			<el-scrollbar class="flex-auto" ref="layoutAsideRef">
				<Vertical :menuList="menuList" />
			</el-scrollbar>
		</el-aside>
	</el-drawer>
</template>

<script>
import Vertical from '@/layout/navMenu/vertical.vue';
import SiderBar from '../siderBar';
export default {
	name: 'layoutAside',
	components: { Vertical },
	data() {
		return {
			menuList: [],
			clientWidth: '',
			SiderBar,
			// 除了全国和县域帐号的其他权限
			chinaMenuList: [
				{
					path: '/home',
					name: 'home',
					meta: {
						title: '数据驾驶舱',
						icon: 'iconfont icon-shujujiashicang',
					},
				},
				{
					path: '/staticTotalView',
					name: 'staticTotalView',
					meta: {
						title: '基础数据统计',
						icon: 'iconfont icon-shujutongji',
					},
					children: [
						{
							path: '/staticTotalView/userInfo',
							name: 'userInfo',
							meta: {
								title: '用户信息统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/familyInfo',
							name: 'familyInfo',
							meta: {
								title: '家庭信息统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/institutionalStatistics',
							name: 'institutionalStatistics',
							meta: {
								title: '机构统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},

						{
							path: '/staticTotalView/resourceStatic',
							name: 'resourceStatic',
							meta: {
								title: '资源统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						// {
						// 	path: '/staticTotalView/healthEqu',
						// 	name: 'healthEqu',
						// 	meta: {
						// 		title: '康养设备统计',
						// 		icon: 'iconfont icon-fuzhiyemian',
						// 	},
						// },
						{
							path: '/staticTotalView/medicalStaffStatistics',
							name: 'medicalStaffStatistics',
							meta: {
								title: '医护人员统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/patrolStatistics',
							name: 'patrolStatistics',
							meta: {
								title: '巡诊统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/residentStatistics',
							name: 'residentStatistics',
							meta: {
								title: '驻派统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/childImmunity',
							name: 'childImmunity',
							meta: {
								title: '接种预防统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/publicEmergencies',
							name: 'publicEmergencies',
							meta: {
								title: '公共突发事件统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/familyDoctor',
							name: 'familyDoctor',
							meta: {
								title: '家庭签约医生统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
					],
				},
				{
					path: '/staticManager',
					name: 'staticManager',
					meta: {
						title: '数据分析',
						icon: 'iconfont icon-shujufenxi',
					},
					children: [
						{
							path: '/staticManager/medicalStaffData',
							name: 'medicalStaffData',
							meta: {
								title: '医护人员数据分析',
							},
						},
						{
							path: '/staticManager/importPersonData',
							name: 'importPersonData',
							meta: {
								title: '重点人群数据分析',
							},
						},
						{
							path: '/staticManager/infectiousDiseaseData',
							name: 'infectiousDiseaseData',
							meta: {
								title: '传染病数据分析',
							},
						},
						{
							path: '/staticManager/healthEduData',
							name: 'healthEduData',
							meta: {
								title: '健康教育数据分析',
							},
						},
						{
							path: '/staticManager/receiveData',
							name: 'receiveData',
							meta: {
								title: '接转诊数据分析',
							},
						},
						{
							path: '/staticManager/inspectData',
							name: 'inspectData',
							meta: {
								title: '检查检验数据分析',
							},
						},
						// {
						// 	path: '/staticManager/makeAroundData',
						// 	name: 'makeAroundData',
						// 	meta: {
						// 		title: '巡诊数据分析',
						// 	},
						// },
						// {
						// 	path: '/staticManager/residencyData',
						// 	name: 'residencyData',
						// 	meta: {
						// 		title: '驻派分析',
						// 	},
						// },
						{
							path: '/staticManager/deviceData',
							name: 'deviceData',
							meta: {
								title: '康养设备数据分析',
							},
						},
					],
				},
			],
			// 全国帐号权限
			allCountryMenuList: [
				{
					path: '/home',
					name: 'home',
					meta: {
						title: '数据驾驶舱',
						icon: 'iconfont icon-shujujiashicang',
					},
				},
				{
					path: '/performanceManagerResult',
					name: 'performanceManagerResult',
					meta: {
						title: '绩效体系',
						icon: 'iconfont icon-jigoutongji',
					},
					children: [
						{
							path: '/performanceManagerResult/index',
							name: 'index',
							meta: {
								title: '乡镇卫生院绩效评价',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
					],
				},
				{
					path: '/staticTotalView',
					name: 'staticTotalView',
					meta: {
						title: '基础数据统计',
						icon: 'iconfont icon-shujutongji',
					},
					children: [
						{
							path: '/staticTotalView/userInfo',
							name: 'userInfo',
							meta: {
								title: '用户信息统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/familyInfo',
							name: 'familyInfo',
							meta: {
								title: '家庭信息统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/institutionalStatistics',
							name: 'institutionalStatistics',
							meta: {
								title: '机构统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},

						{
							path: '/staticTotalView/resourceStatic',
							name: 'resourceStatic',
							meta: {
								title: '资源统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						// {
						// 	path: '/staticTotalView/healthEqu',
						// 	name: 'healthEqu',
						// 	meta: {
						// 		title: '康养设备统计',
						// 		icon: 'iconfont icon-fuzhiyemian',
						// 	},
						// },
						{
							path: '/staticTotalView/medicalStaffStatistics',
							name: 'medicalStaffStatistics',
							meta: {
								title: '医护人员统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/patrolStatistics',
							name: 'patrolStatistics',
							meta: {
								title: '巡诊统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/residentStatistics',
							name: 'residentStatistics',
							meta: {
								title: '驻派统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/childImmunity',
							name: 'childImmunity',
							meta: {
								title: '接种预防统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/publicEmergencies',
							name: 'publicEmergencies',
							meta: {
								title: '公共突发事件统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
						{
							path: '/staticTotalView/familyDoctor',
							name: 'familyDoctor',
							meta: {
								title: '家庭签约医生统计',
								icon: 'iconfont icon-fuzhiyemian',
							},
						},
					],
				},
				{
					path: '/staticManager',
					name: 'staticManager',
					meta: {
						title: '数据分析',
						icon: 'iconfont icon-shujufenxi',
					},
					children: [
						{
							path: '/staticManager/medicalStaffData',
							name: 'medicalStaffData',
							meta: {
								title: '医护人员数据分析',
							},
						},
						{
							path: '/staticManager/importPersonData',
							name: 'importPersonData',
							meta: {
								title: '重点人群数据分析',
							},
						},
						{
							path: '/staticManager/infectiousDiseaseData',
							name: 'infectiousDiseaseData',
							meta: {
								title: '传染病数据分析',
							},
						},
						{
							path: '/staticManager/healthEduData',
							name: 'healthEduData',
							meta: {
								title: '健康教育数据分析',
							},
						},
						{
							path: '/staticManager/receiveData',
							name: 'receiveData',
							meta: {
								title: '接转诊数据分析',
							},
						},
						{
							path: '/staticManager/inspectData',
							name: 'inspectData',
							meta: {
								title: '检查检验数据分析',
							},
						},
						// {
						// 	path: '/staticManager/makeAroundData',
						// 	name: 'makeAroundData',
						// 	meta: {
						// 		title: '巡诊数据分析',
						// 	},
						// },
						// {
						// 	path: '/staticManager/residencyData',
						// 	name: 'residencyData',
						// 	meta: {
						// 		title: '驻派分析',
						// 	},
						// },
						{
							path: '/staticManager/deviceData',
							name: 'deviceData',
							meta: {
								title: '康养设备数据分析',
							},
						},
					],
				},
			],
		};
	},
	computed: {
		// 设置左侧菜单的具体宽度
		setCollapseWidth() {
			let { layout, isCollapse } = this.$store.state.themeConfig.themeConfig;
			let asideBrColor = '';
			layout === 'classic' || layout === 'columns' ? (asideBrColor = 'layout-el-aside-br-color') : '';

			if (layout === 'columns') {
				// 分栏布局，菜单收起时宽度给 1px
				if (isCollapse) {
					return ['layout-aside-width1', asideBrColor];
				} else {
					return ['layout-aside-width-default', asideBrColor];
				}
			} else {
				// 其它布局给 64px
				if (isCollapse) {
					return ['layout-aside-width64', asideBrColor];
				} else {
					return ['layout-aside-width-default', asideBrColor];
				}
			}
		},
		// 获取布局配置信息
		getThemeConfig() {
			return this.$store.state.themeConfig.themeConfig;
		},
	},
	created() {
		this.initMenuFixed(document.body.clientWidth);
		this.setFilterRoutes();
		this.bus.$on('setSendColumnsChildren', (res) => {
			this.menuList = res.children;
		});
		this.bus.$on('layoutMobileResize', (res) => {
			this.initMenuFixed(res.clientWidth);
		});
		// 菜单滚动条监听
		this.bus.$on('updateElScrollBar', () => {
			setTimeout(() => {
				this.$refs.layoutAsideRef.update();
			}, 300);
		});
	},
	methods: {
		// 设置/过滤路由（非静态路由/是否显示在菜单中）
		setFilterRoutes() {
			if (this.$store.state.themeConfig.themeConfig.layout === 'columns') return false;
			let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
			if (userInfo?.regionCode?.length == 6 && userInfo?.roles.includes('crud')) {
				this.menuList = this.filterRoutesFun(this.SiderBar);
			} else if (userInfo?.regionCode == '0') {
				this.menuList = this.filterRoutesFun(this.allCountryMenuList);
			} else {
				this.menuList = this.filterRoutesFun(this.chinaMenuList);
			}
		},
		// 设置/过滤路由 递归函数
		filterRoutesFun(arr) {
			return arr
				.filter((item) => !item.meta.isHide)
				.map((item) => {
					item = Object.assign({}, item);
					if (item.children) item.children = this.filterRoutesFun(item.children);
					return item;
				});
		},
		// 设置菜单导航是否固定（移动端）
		initMenuFixed(clientWidth) {
			this.clientWidth = clientWidth;
		},
	},
	// 页面销毁时
	destroyed() {
		// 取消菜单滚动条监听
		this.bus.$off('updateElScrollBar', () => {});
	},
};
</script>
